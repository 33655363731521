import { TarificationType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Tarification';
import List from '~/containers/Espace/List';
import params from '~/params/tarification.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useParams from '~/utils/useParams';

const PageTarifications: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  const items = useParams({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <List<TarificationType, GenericData<TarificationType>>
        allowSorting
        itemList={Item}
        model={
          new GenericData<TarificationType>({
            espaceId,
            params,
            collectionName: 'tarifications',
            orderDirection: 'asc',
            orderField: 'label',
          })
        }
        search={location.search}
        subMenu={items}
        title={t('params.title')}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageTarifications);
