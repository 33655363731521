import { DocumentType, TarificationType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemTarification: FC<
  ListItemProps<TarificationType, GenericData<TarificationType>>
> = ({ docId, changePosition, index, onClick, model }) => {
  const [doc, setDoc] = useState<DocumentType<TarificationType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      changePosition={changePosition}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemTarification;
